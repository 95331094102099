import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TriggerInterface } from '../interfaces/trigger.interface';
import { AuthService } from './auth.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { HttpClient } from '@angular/common/http';
import { TriggerUtils } from 'src/app/shared/utils/triggers-utils';
import { TriggerAppInterface } from '../interfaces/trigger-app.interface';
import { switchMap } from 'rxjs/operators';
import { deepConvertDates } from '../../shared/utils/utils';

const TRIGGERS_COLLECTION = 'triggers';
const TRIGGERS_COLLECTION_APP = 'triggers-apps';

@Injectable()
export class TriggerService {
    private triggersCollection: AngularFirestoreCollection<TriggerInterface>;
    private triggersAppCollection: AngularFirestoreCollection<TriggerAppInterface>;

    public constructor(
        private readonly firestore: AngularFirestore,
        private readonly authService: AuthService,
        private readonly httpClient: HttpClient
    ) {
        this.triggersCollection = this.firestore.collection<TriggerInterface>(TRIGGERS_COLLECTION);
        this.triggersAppCollection = this.firestore.collection<TriggerAppInterface>(TRIGGERS_COLLECTION_APP);
    }

    public getAll(): Observable<TriggerInterface[]> {
        return this.triggersCollection.valueChanges();
    }

    /*
    * TODO: Dividir en dos funciones, para no pasar currentTypeSite como flag
    */
    public getBySite(siteId: string, currentTypeSite: string): Observable<TriggerInterface[]> {
        const collection = currentTypeSite === 'app' ? TRIGGERS_COLLECTION_APP : TRIGGERS_COLLECTION;
        return this.firestore
            .collection<TriggerInterface>(
                collection,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
    }

    public getAllByTemplateId(templateId: string): Observable<TriggerInterface[]> {
        return this.firestore
            .collection<TriggerInterface>(
                TRIGGERS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('templateId', '==', templateId)
            )
            .valueChanges();
    }

     /*
    * TODO: Dividir en dos funciones, para no pasar currentTypeSite como flag
    */
     public getActiveBySite(siteId: string, currentTypeSite: string = 'web'): Observable<TriggerInterface[]> {
      const collection = currentTypeSite === 'app' ? TRIGGERS_COLLECTION_APP : TRIGGERS_COLLECTION;
      return this.firestore
          .collection<TriggerInterface>(
              collection,
              (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
          )
          .valueChanges();
  }
    /*
    * TODO: Dividir en dos funciones, para no pasar currentTypeSite como flag
    */
    public getAllBySite(siteId: string, currentTypeSite: string = 'web'): Observable<TriggerInterface[]> {
        const collection = currentTypeSite === 'app' ? TRIGGERS_COLLECTION_APP : TRIGGERS_COLLECTION;
        return this.firestore
            .collection<TriggerInterface>(
                collection,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
    }

    public getPushAndNewsletterBySiteId(siteId: string): Observable<TriggerInterface[]> {
        const pushTriggers$: Observable<TriggerInterface[]> = this.firestore
            .collection<TriggerInterface>(
                TRIGGERS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('siteId', '==', siteId).where('type', '==', CONSTANTS.PROMETEO_TRIGGERS_PUSH_TYPE)
            )
            .valueChanges();

        const newsletterTriggers$: Observable<TriggerInterface[]> = this.firestore
            .collection<TriggerInterface>(
                TRIGGERS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('siteId', '==', siteId).where('type', '==', CONSTANTS.PROMETEO_TRIGGERS_NEWSLETTER_TYPE)
            )
            .valueChanges();

        return combineLatest(pushTriggers$, newsletterTriggers$).pipe(
            map(([pushTriggers, newsletterTriggers]) => [...pushTriggers, ...newsletterTriggers])
        );
    }

    public get(triggerId: string): Observable<TriggerInterface> {
        return this.triggersCollection.doc<TriggerInterface>(triggerId).valueChanges();
    }

    public getTriggerApp(triggerId: string): Observable<TriggerAppInterface> {
        return this.triggersAppCollection.doc<TriggerAppInterface>(triggerId).valueChanges();
    }

    public updateVersion(media: string): Observable<{ message: string }> {
        const versionUrl = CONSTANTS.API_REST_UPDATE_TRIGGER_VERSION.replace(':mediaId', media);
        return this.httpClient.post<{ message: string }>(versionUrl, {}).pipe(take(1));
    }

    public deactivateDefaultBlockByMedia(siteId: string): Observable<void[]> {
        return this.firestore
            .collection<TriggerInterface>(
                TRIGGERS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('siteId', '==', siteId).where('defaultBlockArticle', '==', true)
            )
            .valueChanges()
            .pipe(
                take(1),
                switchMap(triggers => {
                    const updateObservables = triggers.map(t =>
                        this.triggersCollection
                            .doc<TriggerInterface>(t.id)
                            .update(Object.assign({}, { ...t, defaultBlockArticle: false }))
                    );
                    return updateObservables && updateObservables.length
                        ? forkJoin(updateObservables)
                        : of(null);
                })
            );
    }

    public checkDefaultBlockTrigger(siteId: string, value: boolean): Observable<void[]> {
        return value ? this.deactivateDefaultBlockByMedia(siteId) : of(null);
    }

    public createId(): string {
        return this.firestore.createId();
    }

    public add(trigger: TriggerInterface, isIdCreated: boolean): Observable<void> {
        trigger.id = !isIdCreated ? this.firestore.createId() : trigger.id;
        trigger.createdAt = firebase.firestore.Timestamp.now();
        trigger.updatedAt = firebase.firestore.Timestamp.now();
        trigger.updatedBy = this.authService.getEmail();
        TriggerUtils.replaceTriggerIdInTriggerCode(trigger, trigger.id);
        return from(this.triggersCollection.doc<TriggerInterface>(trigger.id).set(deepConvertDates(trigger)));
    }

    public addApp(trigger: TriggerAppInterface): Observable<void> {
        trigger.createdAt = firebase.firestore.Timestamp.now();
        trigger.updatedAt = firebase.firestore.Timestamp.now();
        trigger.updatedBy = this.authService.getEmail();
        return from(this.triggersAppCollection.doc<TriggerAppInterface>(trigger.id).set(deepConvertDates(trigger)));
    }

    public delete(triggerId: string): Observable<void> {
        return from(this.triggersCollection.doc<TriggerInterface>(triggerId).delete());
    }

    public deleteApp(triggerId: string): Observable<void> {
        return from(this.triggersAppCollection.doc<TriggerAppInterface>(triggerId).delete());
    }

    public update(triggerId: string, dataToUpdate: Partial<TriggerInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        dataToUpdate.html && TriggerUtils.replaceTriggerIdInTriggerCode(dataToUpdate, dataToUpdate.id);
        return from(this.triggersCollection.doc<TriggerInterface>(triggerId).update(deepConvertDates(dataToUpdate)));
    }

    public updateApp(triggerId: string, dataToUpdate: Partial<TriggerAppInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(
            this.triggersAppCollection.doc<TriggerAppInterface>(triggerId).update(deepConvertDates(dataToUpdate))
        );
    }

    public siteIsApp(type: string): boolean {
      return type === 'app';
    }

    public getCollectionName(){
      return TRIGGERS_COLLECTION;
    }
}
