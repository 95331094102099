export default class ConstantMessages {
  // TODO definir un formato de constantes y aplicarlo a todo el proyecto.
  // Posiblemente alterando este archivo

  // Widget messages
  public static readonly WidgetToggleText =
    'Por favor asegúrese de quitar el elemento HTML del widget del página del medio';
  public static readonly DeleteWidgetTitle =
    'Por favor asegúrese de quitar el elemento HTML del widget del página del medio. ¿Está seguro de borrarlo?';
  //End of Widget messages

  // Widget in Test A/B messages
  public static readonly ConditionWidgetInTestAb =
    'Las condiciones para resolver el test A/B se han cumplido. \n Ha ganado el widget ';
  public static readonly PlaceScriptWidgetInTestAb = 'Coloca el script en tu página<br/><br/>';

  // End Widget in Test A/B messages

  // Triggers in Test A/B messages.
  public static readonly TriggerInTestAbTitle = 'Activador pertenece a Test A/B';
  public static readonly TriggerInTestAbText = 'El activador no se puede eliminar mientras pertenezca a un Test A/B';
  // End Triggers in Test A/B messages.

  // Triggers in Test A/B messages.
  public static readonly TriggerCompatibleWithTitle = 'Activador es compatible con otros activadores';
  public static readonly TriggerCompatibleWithText =
    'El activador no se puede eliminar mientras sea compatible con: :TRIGGERS';
  // End Triggers in Test A/B messages.

  // Save messages.
  public static readonly SaveSuccessText = 'Guardado realizado con éxito';
  public static readonly SaveErrorText = 'Ha ocurrido un error con el guardado';
  public static readonly NO_SAVE_DATA_QUESTION = 'Hay datos sin guardar, ¿desea abandonar la página sin guardarlos?';
  // End Save messages.

  // Action messages.
  public static readonly ActionSuccessTitle = 'Acción realizada';
  public static readonly ActionSuccessText = 'Se ha realizado la acción con éxito';
  public static readonly ActionSuccessTextCustom = 'Se ha realizado correctamente :INFO';
  // End Save messages.

  // Delete messages.
  public static readonly DeleteWarningTitle = '¿Estás seguro de borrarlo?';
  public static readonly DeleteWarningText = 'Esta acción no se puede deshacer';
  public static readonly DeleteTitle = 'Borrado de :typeName';
  public static readonly DeleteSuccess = 'Se ha borrado :typeName con nombre :name con éxito';
  public static readonly DeleteError = 'Ha ocurrido un error al borrar :typeName con nombre :name';
  public static readonly DeleteWartingOtherElement =
    'Si se borra esta fuente de datos, se actualizarán las siguientes fuentes de datos segmentadas: :datasourceId.';
  public static readonly DeleteWarningNotDeleteTitle = 'Esta fuente de datos no se puede borrar';
  public static readonly DeleteWarningNotDeleteText =
    'Esta siendo usada en las siguientes fuente de datos: :datasourceId. En alguna por defecto.';
  // End Delete messages.

  // Clone messages.
  public static readonly CloneWarningTitle = 'Información de copiado';
  public static readonly CloneWarningText = '¿Quieres copiar este contenido?';
  public static readonly CloneTitle = 'Copiado de :typeName';
  public static readonly CloneSuccess = 'Se ha copiado con éxito :typeName con nombre :name con éxito';
  public static readonly CloneError = 'Error al copiar :typeName :name';
  // End Clone messages.

  // Check statistics messages.
  public static readonly CheckStatisticsTitle = 'Comprobación de utilización';
  public static readonly CheckStatisticsText = 'Ha ocurrido un error durante la comprobación';
  // End Check statistics messages.

  // Check segment being used messages.
  public static readonly CheckSegmentBeingUsedTitle = 'Espere por favor';
  public static readonly CheckSegmentBeingUsedText =
    'Comprobando si el segmento está siendo utilizado en algún componente...';
  public static readonly DeleteSegmentAdManagerWarning =
    'No se puede borrar este segmento porque esta compartido con GAM';
  public static readonly DeleteOpSegmentAdManagerWarning =
    'No se puede borrar este segmento porque alguna de las operaciones a las que pertenece está compartida con GAM';
  public static readonly DeleteSegmentWithOperation =
    'Este segmento pertenece a una o varias operaciones. Si lo eliminas, también se eliminarán las operaciones a las que pertenece';
  // End Check segment being used messages.

  // Template messages.
  public static readonly CreateTriggerTemplateTitle = 'Plantilla de activador';
  public static readonly DeleteTemplateTitle = 'Eliminar plantilla';
  public static readonly DeleteTemplateText = '¿Está seguro de querer borrar la plantilla?';

  // End Template messages.

  // Error messages.
  public static readonly ErrorTitle = 'Error';
  public static readonly ErrorText = 'Ha ocurrido un error';
  // End Error messages.

  // Warning messages.
  public static readonly WarningTitle = 'Advertencia';
  // End Warning messages.

  public static readonly CancelText = 'Cancelar';
  public static readonly AcceptText = 'OK';
  public static readonly WarningType = 'warning';
  public static readonly InfoType = 'info';
  public static readonly SuccessType = 'success';
  public static readonly ErrorType = 'error';

  // Components names.
  public static readonly TriggerName = 'activador';
  public static readonly NewsletterName = 'newsletter';
  public static readonly TemplateName = 'plantilla';
  public static readonly DatasourceName = 'fuente de datos';
  public static readonly WidgetName = 'widget';
  public static readonly TestABName = 'test A/B';
  public static readonly TopicName = 'tópico';
  public static readonly PushNName = ' notificación push';
  public static readonly FunctionName = 'función';
  public static readonly HistoricalOperationName = 'operación histórica';
  public static readonly SegmentName = 'segmento';
  public static readonly UtmCampaignName = 'utm campaign';
  // End Components names.

  // Modules (generic)
  public static readonly MODULE_RESTRICTED = 'No tiene acceso a este módulo';
  public static readonly MODULE_DISABLED =
    'Todavía no puedes usar este módulo. Debes activarlo desde la configuración del sitio correspondiente.';
  // End Modules

  // PAYWALL
  public static readonly PAYWALL_MODULE = 'Módulo de Paywall';
  public static readonly PAYWALL_CONFIG_REQUIRED =
    'Faltan elementos por configurar para poder utilizar correctamente el módulo de Paywall.';
  public static readonly PAYWALL_UPDATE = 'Actualización de Paywall';
  public static readonly PAYWALL_UPDATE_ACTIVE_SUCCESS = `El módulo Paywall ha sido :active correctamente.`;
  public static readonly PAYWALL_UPDATE_ERROR = `El módulo Paywall no ha podido ser actualizado.`;
  public static readonly PAYWALL_CREATE_PRODUCT = `Se ha creado un producto nuevo con id: :productID`;
  public static readonly PAYWALL_PRODUCT_ERROR = `Error: :error`;
  public static readonly PAYWALL_UPDATE_PRODUCT = `Se ha editado el producto con identificador: :productID`;
  public static readonly PAYWALL_UPDATE_PRODUCT_RETROACTIVITY = `También se aplicaron cambios retroactivos, es posible que tarde unos minutos en reflejarse en las subscripciones.`;
  public static readonly PAYWALL_SUBSCRIPTION_DETAIL = 'Detalles de suscripción';
  public static readonly PAYWALL_SUBSCRIPTION_LIST = 'Lista de suscripciones';
  public static readonly PAYWALL_SCHEDULE_LIST = 'Lista de suscripciones futuras';
  public static readonly PAYWALL_LIST_LOAD_ERROR =
    'Ha habido un error con la carga de datos, por lo que los resultados mostrados corresponden a la consulta anterior.';
  public static readonly PAYWALL_SUBSCRIPTION_REFUND = 'Suscripción devuelta y cancelada inmediatamente';
  public static readonly PAYWALL_RENOVATION_CANCELED = 'Siguiente renovación cancelada';
  public static readonly PAYWALL_SCHEDULE_CANCELED = 'Suscripción prevista eliminada';
  public static readonly PAYWALL_SUPPORT_ADDED = 'Comentario de soporte añadido';
  public static readonly PAYWALL_CONFIG_PAGE = 'Configuración de páginas';
  public static readonly PAYWALL_CONFIG_PAGE_CONFIRM = '¿Seguro que desea quitar esta página?';
  public static readonly PAYWALL_FORM_ERROR_VALIDATION = 'Faltan campos requeridos.';
  public static readonly PAYWALL_CUSTOMER_LIST = 'Lista de suscriptores';
  //PRODUCTS
  public static readonly PAYWALL_ERROR_PRODUCT_LIST = 'Listado de productos';

  //CAMPAIGNS
  public static readonly PAYWALL_ERROR_CAMPAIGN_LIST = 'Listado de campañas';
  public static readonly PAYWALL_ARCHIVE_CAMPAIGN = '¿Deseas cambiar el estado de la campaña?';
  public static readonly PAYWALL_ERROR_DISABLE_CAMPAIGN = 'Campaña vinculada a productos activos';
  public static readonly PAYWALL_ERROR_CREATE_CAMPAIGN = 'Error al crear la campaña.';
  public static readonly PAYWALL_ERROR_UPDATE_CAMPAIGN = 'Error al editar la campaña.';
  public static readonly PAYWALL_ERROR_CAMPAIGN = 'Error al obtener la campaña.';
  public static readonly PAYWALL_ERROR_PRODUCTS_CAMPAIGN = 'Error al obtener los productos ligados a esta campaña.';

  //PUBSUB
  public static readonly PAYWALL_ERROR_WEBHOOK = `Error en la sección de Webhooks`;
  public static readonly PAYWALL_ERROR_WEBHOOK_TEXT = `Un campo no ha sido rellenado correctamente.`;

  //PRIORITY
  public static readonly PAYWALL_ERROR_PRIORITY_SAVING =
    'Ha ocurrido un error al guardar las prioridades de los productos. Por favor, inténtelo de nuevo más tarde.';
  public static readonly PAYWALL_SUCCESS_PRIORITY_SAVING =
    'Se ha actualizado el orden de los productos, es posible que tarde unos minutos en reflejarse.';

  // End Paywall

  // FIREBASE
  public static readonly FIREBASE_FAIL = 'Fallo en Firestore';
  // End Firebase

  // Images.
  public static readonly ERROR_LOADING_IMAGE = 'Error al cargar la imagen';
  public static readonly ERROR_WRONG_IMAGE = 'Imagen incorrecta';
  public static readonly ERROR_IMAGE_TOO_LARGE_KB = 'La imagen excede el peso permitido: 500kb';
  public static readonly ERROR_IMAGE_TOO_LARGE = 'Imagen demasiado grande';
  // End Images.

  //TAX
  public static readonly PAYWALL_ERROR_DISABLE_TAX = 'Impuesto vinculado a productos activos';
  public static readonly PAYWALL_ERROR_ACTIVE_PRODUCT = 'Impuesto no disponible';

  // Push Notification.
  public static readonly PUSH_NOTIFICATION_TITLE = 'Notificación :title'; //TODO refactorizar a template literal
  public static readonly INVALID_IMAGE = 'Imagen con formato inválido';
  public static readonly ERROR_PUSH_NOTIFICATION = 'Ha ocurrido un error con el envío de la Notificación';
  public static readonly ERROR_PUSH_NOTIFICATION_SAVING =
    'Ha ocurrido un error al guardar los datos de la Notificación';
  public static readonly ERROR_PUSH_NOTIFICATION_EMPTY_ARTICLE = 'El artículo está vacío';
  public static readonly ERROR_PUSH_GENERATING_ARTICLE = 'Error al generar el artículo';
  public static readonly SUCCESS_PUSH_NOTIFICATION_SEND = 'Notificación enviada con éxito';
  public static readonly WARNING_PUSH_NOTIFICATION_SEND =
    'Notificación WEB enviada con éxito para firebase, para safari se encontraron problemas';

  public static readonly TOPIC_TITLE = 'Tópico :topic';
  public static readonly TOPIC_GENERIC_TITLE = 'Tópicos';
  public static readonly SUCCESS_TOPIC_SAVED = 'Tópico guardado correctamente';
  public static readonly ERROR_TOPIC_NOT_SAVED = 'Error al guardar el tópico';
  public static readonly ERROR_STATISTICS_DATE_TOPICS = 'Error al obtener los datos tipo fecha';
  // End Push Notification.

  // Newsletters.
  public static readonly NEWSLETTER_TITLE = 'Campaña :title';
  public static readonly SUCCESS_NEWSLETTER_SENDING = 'Campaña enviada con éxito';
  public static readonly ERROR_NEWSLETTER_SENDING = 'Ha ocurrido un error con el envío de la campaña';
  public static readonly DATA_NOT_FOUND_TITLE = 'No se encontraron datos';
  public static readonly DATA_NOT_FOUND_TEXT = 'No hay datos para esta notificación';
  public static readonly ERROR_SAVE_IMAGE = 'Ha ocurrido un error al guardar la imagen';
  // End Newsletters.

  // Datasources.
  public static readonly DATASOURCE_NAME = 'Fuente de datos :name';
  public static readonly ERROR_DATA_SAVING = 'Ha ocurrido un error con el guardado';
  // End Datasources.

  //Sites
  public static readonly SITE_CONFIGURATION_TITLE = 'Configuración de Sitios';

  // ThalIA
  public static readonly THALIA_MODULE = 'Módulo ThalIA';
  public static readonly THALIA_CONFIG_REQUIRED =
    'Faltan elementos por configurar para poder utilizar correctamente el módulo ThalIA.';
  public static readonly THALIA_UPDATE = 'Actualización de ThalIA';
  public static readonly THALIA_UPDATE_ACTIVE_SUCCESS = (status: string): string => `El módulo ThalIA ha sido ${status} correctamente.`;
  public static readonly THALIA_UPDATE_ERROR = `El módulo ThalIA no ha podido ser actualizado.`;
  public static readonly THALIA_GET_CONFIG_ERROR = `La configuración de ThalIA no ha podido ser recuperada: `;
  // End ThalIA
}

export enum NOTIFY_TYPE {
  // Align with SweetAlertType
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  QUESTION = 'question',
}

export enum NOTIFY_INPUT {
  NUMBER = 'number',
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  TEL = 'tel',
  RANGE = 'range',
  TEXT_AREA = 'textarea',
  SELECT = 'select',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  URL = 'url',
}

export enum NOTIFY_BUTTON {
  APPLY = 'Aplicar',
  NOT_APPLY = 'No aplicar',
  CONFIRM = 'Confirmar',
  CANCEL = 'Cancelar',
}

export const NOTIFY_MESSAGE = {
  ALERT: {
    GENERIC: {
      TITLE: {
        SUCCESS: 'Realizado',
        ERROR: 'Error',
        INFO: '',
        WARNING: '',
        INPUT: 'Introduce valor',
      },
      MSG: {
        SUCCESS: 'Se ha completado la acción con éxito',
        ERROR: 'Se ha producido un error al realizar la acción',
        INFO: '',
        WARNING: '',
      },
    },
    RADIO_INPUT: {
      VALIDATOR_MSG: 'Es necesario que selecciones una de las opciones.',
    },
    TEXT_INPUT: {
      VALIDATOR_MSG: 'Es necesario un valor',
    },
    STEPPER: {
      SECTION_ERROR: 'Sección con errores',
      TITLE_ERROR: 'Campos incorrectos',
      MESSAGE_ERROR: 'No podrás guardar hasta que estén correctos',
    },
  },
  CLIPBOARD: {
    SUCCESS: 'Texto copiado',
  },

  MSG: {
    GENERIC: 'Ha ocurrido un error en la última operación. Por favor intente nuevamente.',
  },

  PAYWALL: {
    CONFIG: {
      TITLE: 'Configuración',
      MSG: {
        EMPTY_URL: 'Paywall Server URL not defined yet',
      },
      HTML: {
        TITLE: 'Configuración de páginas',
      },
      CHECKOUT: {
        TITLE: 'Configuración del formulario de compra',
      },
      CANCELLATION_PROCESS: {
        TITLE: 'Configuración del proceso de cancelación',
      },
      RRM: {
        TITLE: 'Configuración de Reader Revenue Manager by Google',
        PLANS: {
          TITLE: 'Configuración de planes RRM',
        }
      },
      SHARED: {
        NEW_DATA:
          'Se han detectado cambios externos en la configuración. El formulario se reiniciará con los nuevos valores',
      },
      VERSIONS: {
        TITLE: 'Versiones de configuración',
        CONFIRM_RESTORE: '¿Desea restaurar esta versión?',
        CONFIRM_DELETE: '¿Desea eliminar esta versión?',
      },
      INCLUDED: {
        TITLE: 'Configuración de contenido incluido en la suscripción',
      }
    },
    PRODUCT: {
      TITLE: {
        RETROACTIVITY: 'Retroactividad',
        DELETE: 'Eliminación de producto',
        LIST: 'Listado de productos',
        TOGGLE_STATUS: 'Cambio de estado de producto',
        FORM: 'Formulario de producto',
      },
      MSG: {
        PRICES_OR_TAX_QUESTION: `Se ha detectado cambio en el precio de renovación o en el impuesto. Los cambios aplicarán a las sucripciones actuales y programadas, ¿Desea continuar?`,
        DELETE_DONE: 'Eliminación realizada',
        DELETE_QUESTION:
          '¿Seguro que desea eliminar el producto? (se desactivará y no será visible en los listados ni en escaparate)',
        TOGGLE_ACTIVE_QUESTION: '¿Seguro que desea cambiar el estado del producto?',
        //TODO: No se si tipar el tax en un archivo de constantes.
        TOGGLE_ACTIVE_ERROR: ({ tax }): string =>
          `El impuesto ${tax.displayName} (${tax.id}) no está activo, por favor actívelo o seleccione otro.`,
      },
      PRIORITY: {
        TITLE: 'Prioridad de productos',
      },
    },
    OPERATION: {
      TITLE: {
        FORM: 'Formulario de operaciones',
        RRM_FORM: 'Formulario de operaciones',
        RRM_LIST: 'Listado de operaciones RRM',
      },
      MSG: {
        NOT_FOUND: 'Error al localizar la operación.',
        ERROR_LIST: 'Ha habido un error con la carga de datos',
      },
    },
    REFUNDS: {
      LIST: {
        TITLE: 'Listado de reembolsos',
      },
    },
    CUSTOMER: {
      TITLE: {
        FORM: 'Formulario del suscriptor',
      },
      MSG: {
        INVALID_TAX_ID:
          'El número de identificación (NIF, DNI, NIE o Pasaporte) de la dirección de facturación es inválido.',
        SHIPPING_UPDATE_SUCCESS: 'La actualización de la dirección de facturación se ha realizado con éxito.',
      },
    },
    SCHEDULE: {
      UPDATE_START_DATE: {
        TITLE: 'Modificación de la fecha de comienzo',
        WARNINGS: 'Existen avisos, ¿desea continuar?',
        WARNINGS_NOT_ACCEPTED: 'Existen warnings y el usuario decide no seguir con la operación',
      },
    },
    CONTRACTS: {
      TITLE: 'Contratos en Gigya',
      MANUAL_CONTRACT_ON_GIGYA: 'Contrato manual en Gigya',
      NO_GIGYA_UID: 'No hemos podido leer correctamente el uid del usuario',
      MANUAL_CONTRACT_DELETE_CONFIRM: '¿Confirma que desea borrar el contrato manual?',
    },
    SUBSCRIPTION_DETAIL: {
      TITLE: 'Detalles de suscripción',
      GRACE_CANCEL: {
        SUCCESS: 'Cancelación de suscripción en periodo de gracia realizada con éxito',
        FAILED: 'Error al cancelar la suscripción en periodo de gracia',
      },
    },
    SUBSCRIPTIONS: {
      RRM: {
        LIST: {
          TITLE: 'Listado de suscripciones RRM',
        },
      },
    },
  },
  THALIA: {
    CONTENT: {
      SUCCESS_CREATE: (contentId: number): string =>
        `Contenido con id ${contentId} y con su primera versión creado con éxito.`,
      ERROR_CREATE: 'Error al crear el contenido',
      DELETE_CONFIRM: '¿Seguro que desea eliminar el contenido?',
      DELETE_SUCCESS: (id: number): string => `Contenido con id ${id} eliminado con éxito`,
      MULTIPLES_TITLES: {
        SUCCESS: 'Título actualizado correctamente',
      }
    },
    MODELS: {
      CONTENT_GENERATION: {
        TITLE: 'Modelos de generación de contenido',
        CREATE: 'Añadir modelo de contenido',
      },
      TRANSCRIPT: {
        TITLE: 'Modelos de transcripción',
      },
    },
    TRANSCRIPTS: {
      TITLE: 'Transcripciones',
      CREATE: 'Añadir transcripción',
      DELETE_CONFIRM: '¿Seguro que desea eliminar la transcripción?',
      DELETE_SUCCESS: 'Transcripción eliminada con éxito',
      FORMAT_NOT_ACCEPTED: 'Formato de archivo no aceptado',
      WITHOUT_PERMISSIONS_TO_CREATE: 'No tienes permisos para crear transcripciones',
      WITHOUT_PERMISSIONS_TO_READ: 'No tienes permisos para ver transcripciones',
      VIDEO_DETECTED: 'Códec de video detectado, es posible que su archivo no pueda ser transcrito',
      FILE_WITH_ERRORS: 'Archivo dañado, por favor, intente con uno nuevo',
    },
    PROMPTS: {
      NEW_VERSION: 'Generando nueva versión',
      SUCCESS_DELETE_VERSION: 'Versión eliminada',
      CANT_DELETE_ACTIVE_VERSION: 'No se puede eliminar la versión activa salvo que sea la única versión',
      DELETE_CONFIRM: '¿Seguro que desea eliminar la versión?',
    },
  },
  SHARED: {
    TITLE: {
      CLONE: 'Información de copiado',
    },
    MSG: {
      CLONE_WARNING: '¿Quieres copiar este contenido?',
      SAVE_WARNING: '¿Desea guardar los cambios?',
      FORM_WITH_ERRORS: 'Hay algún error o campo requerido vacío en el formulario',
      FORM_WITHOUT_CHANGES: 'No se han realizado cambios en el formulario',
      DELETE:{
        WARNING: '¿Estás seguro de querer borrarlo?',
        DONE: 'Eliminación realizada',
      }
    },
    UNKNOWN_ERROR: 'Ha ocurrido un error',
    SUCCESS: 'Operación realizada con éxito',
    RETRY_LIMIT_REACHED: 'Los reintentos han excedido el límite',
  },
  ERRORS:{
    NOT_FOUND: 'No se encontró el recurso solicitado',
  },
  TRIGGER: {
    SUMMARY_TEMPLATE: {
      DEFAULT: ({ updatedBy, createdAt, segments }): string =>
        `Este activador no tiene cambios futuros programados:
        Actualizado por ${updatedBy} en ${createdAt?.toDate?.().toLocaleString()}.
        Segmentos: ${segments}`,
      TEST_AB: ({ testABList }): string =>
        `Este activador está siendo utilizado en el test A/B con ID ${testABList.join(', ')}`,
      PROGRAM_DATE: ({ active, date }): string => {
        if (active) return `Está programado para desactivarse en la siguiente fecha:\n${date}`;
        return `Está programado para activarse en la siguiente fecha:\n${date}`;
      },
    },
  },
  FEATURE:{
    TITLE: 'Funcionalidad',
    NOT_ACTIVE: 'La funcionalidad no está activa en este momento',
  },
  CORE: {
    TITLE: 'Hipatia',
    USER_NOT_VALID: 'El usuario no es válido o ha terminado su sesión',
    NO_ACCESS: 'No tiene acceso a esta página',
    NO_MODULE_ACCESS: 'Este medio no tiene acceso al módulo indicado',
    NO_MODULES_ALLOWED: 'No se han encontrado módulos activos con los permisos adecuados',
    IDLE:{
      TITLE: 'Inactividad',
      USER_IDLE: 'No se ha detectado actividad, su sesión se cerrará',
      SESSION_CLOSED: 'Su sesión ha sido cerrada por inactividad',
    }
  }
};

export const SYSTEM_MESSAGES = {
  USER_NOT_VALID: 'El usuario no es válido',
  USER_HAS_NOT_ACCESS: 'El usuario no tiene acceso a esta página',
};
