import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConstantsTriggers } from 'src/app/shared/utils/constants-triggers';
import TriggerMetaInterface from '../interfaces/trigger/trigger-meta.interface';
import { NewslettersSubscriptionService } from './newsletters-subscription.service';
import { PushSubscriptionService } from './push-subscription.service';
import { TestAbService } from './test-ab.service';
import { TriggerService } from './trigger.service';
import firebase from 'firebase/compat/app';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { PushSubscriptionInterface } from '../interfaces/push-subscription.interface';
import { TriggerInterface } from '../interfaces/trigger.interface';
import { NewslettersSubscriptionInterface } from '../interfaces/newsletters-subscription.interface';

type GenericTrigger = PushSubscriptionInterface | TriggerInterface | NewslettersSubscriptionInterface
@Injectable({
  providedIn: 'root'
})
export class AllTriggersService {

  constructor(
    private triggerService: TriggerService,
    private pushSubscriptionService: PushSubscriptionService,
    private newsletterSubscriptionService: NewslettersSubscriptionService,
    private testAbService: TestAbService,
    private readonly firestore: AngularFirestore
  ) { }

  getAllByMedia(siteId: string): Observable<TriggerMetaInterface[]> {
    const triggers$ = this.triggerService.getActiveBySite(siteId);
    const pushSubscriptions$ = this.pushSubscriptionService.getActiveBySite(siteId);
    const newslettersSubscriontions$ = this.newsletterSubscriptionService.getActiveBySite(siteId);

    return combineLatest([triggers$, pushSubscriptions$, newslettersSubscriontions$]).pipe(
      switchMap(([triggers, pushSubscriptions, newslettersSubscriptions]) => {
        const all = [];
        all.push(...triggers.map(t => ({id: t.id, name: t.name, type: ConstantsTriggers.globalTriggerTypes[2], html: t.design.html, isIntoTestAB: t.isIntoTestAB })));
        all.push(...pushSubscriptions.map(p => ({id: p.id, name: p.name, type: ConstantsTriggers.globalTriggerTypes[3], html: p.design.html, isIntoTestAB: p.isIntoTestAB })));
        all.push(...newslettersSubscriptions.map(n => ({id: n.id, name: n.title, type: ConstantsTriggers.globalTriggerTypes[4], html: n.design.html , isIntoTestAB: n.isIntoTestAB})));
        return of(all);
      })
    );
  }

  getActiveAndNonActiveByMedia(siteId: string): Observable<TriggerMetaInterface[]> {
    const triggers$ = this.triggerService.getBySite(siteId, 'web');
    const pushSubscriptions$ = this.pushSubscriptionService.getBySite(siteId);
    const newslettersSubscriontions$ = this.newsletterSubscriptionService.getBySite(siteId);

    return combineLatest([triggers$, pushSubscriptions$, newslettersSubscriontions$]).pipe(
      switchMap(([triggers, pushSubscriptions, newslettersSubscriptions]) => {
        const all = [];
        all.push(...triggers.map(t => ({id: t.id, name: t.name, type: ConstantsTriggers.globalTriggerTypes[2], html: t.design.html, isIntoTestAB: t.isIntoTestAB })));
        all.push(...pushSubscriptions.map(p => ({id: p.id, name: p.name, type: ConstantsTriggers.globalTriggerTypes[3], html: p.design.html, isIntoTestAB: p.isIntoTestAB })));
        all.push(...newslettersSubscriptions.map(n => ({id: n.id, name: n.title, type: ConstantsTriggers.globalTriggerTypes[4], html: n.design.html , isIntoTestAB: n.isIntoTestAB})));
        return of(all);
      })
    )
  }

  getMaxPriorityByMedia(siteId: string): Observable<number> {
    const triggers$ = this.triggerService.getActiveBySite(siteId);
    const pushSubscriptions$ = this.pushSubscriptionService.getActiveBySite(siteId);
    const newslettersSubscriontions$ = this.newsletterSubscriptionService.getActiveBySite(siteId);
    const testAb$ = this.testAbService.getActiveBySite(siteId);

    return combineLatest([triggers$, pushSubscriptions$, newslettersSubscriontions$, testAb$]).pipe(
      switchMap(([triggers, pushSubscriptions, newslettersSubscriptions, testAb]) => {
        const all = [];
        all.push(...triggers);
        all.push(...pushSubscriptions);
        all.push(...newslettersSubscriptions);
        all.push(...testAb);
        all.sort((a, b) => {
          return a.priority - b.priority;
        });
        return all.length ?  of(all[all.length - 1].priority + 1): of(1);
      })
    );
  }

  getMaxPriorityAppsByMedia(siteId: string, currentSiteType: string): Observable<number> {
    return this.triggerService.getActiveBySite(siteId, currentSiteType)
      .pipe(
        switchMap((triggersApps) => {
          triggersApps.sort((a, b) => {
            return a.priority - b.priority;
          });
          return of(triggersApps[triggersApps.length - 1].priority + 1);
        })
      );
  }

  public getAllProgram(siteId: string, collection: string): Observable<GenericTrigger[]> {
    const triggersPeriodic$ = this.firestore
      .collection<GenericTrigger>(
          collection,
          (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', false).where('hasPeriodicSchedule', '==', true)
      ).valueChanges();
    const triggersInactiveNotPeriodic$ = this.firestore
      .collection<GenericTrigger>(
        collection,
          (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', false)
      ).valueChanges().pipe(
        map((trigger) =>
          trigger.filter((programTrigger) =>
            (programTrigger?.programDates?.filter(date => date.seconds > firebase.firestore.Timestamp.now().seconds).length
            && !programTrigger?.hasPeriodicSchedule))
        ),
      );
    return combineLatest([triggersPeriodic$, triggersInactiveNotPeriodic$])
      .pipe(map(([triggersPeriodic, triggersProgram]) => [...triggersPeriodic, ...triggersProgram]));
  }
}
