import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { FirestoreInterface } from '../interfaces/firestore.interface';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AuthService } from './auth.service';
import { TriggerUtils } from 'src/app/shared/utils/triggers-utils';
import { ConstantsServices } from 'src/app/shared/utils/constants-services';
import { deepConvertDates } from '../../shared/utils/utils';

export const COLLECTION_NAME = new InjectionToken<string>('collectionName');

@Injectable()
export class FirestoreService<T>implements FirestoreInterface<T>  {
  /*TODO: Instanciar los valores que se estan devolviendo con una funcion que tambien se reciba por argumento para poder controlar la informacion que se devuelve
   y asegurarnos que no se pueda dar la posibilidad de que se devuelva información erronea sin saberlo y que de lugar a errores*/
    public collection: AngularFirestoreCollection<T>;

    constructor(@Inject(COLLECTION_NAME) public readonly collectionName: string, public readonly firestore: AngularFirestore, public readonly authService: AuthService) {
        if (!this.collectionName) throw new Error('Firestore called with no collection name');

        this.collection = this.firestore.collection<T>(this.collectionName);
    }

    public createId(): string {
        return this.firestore.createId();
    }

    public add(component: any, isIdCreated: boolean = false): Observable<void> {
        const componentId = !isIdCreated ? this.firestore.createId() : component.id;
        component.id = componentId;
        component.createdAt = firebase.firestore.Timestamp.now();
        component.updatedAt = firebase.firestore.Timestamp.now();
        component.updatedBy = this.authService.getEmail();

        // Todo: Pendiente de delegar a los consumidores de este servicio que hagan uso de esta lógica
        if (this.collectionName === ConstantsServices.CollectionsName.newsletterSubscriptionCollection) {
          TriggerUtils.replaceTriggerIdInTriggerCode(component, componentId);
        }

        return from(this.collection.doc<T>(componentId).set(deepConvertDates(component)));
    }

    public update(componentId: string, dataToUpdate: Partial<any>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();

        // Todo: Pendiente de delegar a los consumidores de este servicio que hagan uso de esta lógica
        if (this.collectionName === ConstantsServices.CollectionsName.newsletterSubscriptionCollection) {
          TriggerUtils.replaceTriggerIdInTriggerCode(dataToUpdate, componentId);
        }

        return from(this.collection.doc<T>(componentId).update(deepConvertDates(dataToUpdate)));
    }

    public getAll(): Observable<T[]> {
        return this.collection.valueChanges();
    }

    public getBySite(siteId: string): Observable<T[]> {
        return this.firestore
            .collection<T>(
                this.collectionName,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
    }

    public getActiveBySite(siteId: string): Observable<T[]> {
        return this.firestore
            .collection<T>(
                this.collectionName,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

    public getById(componentId: string): Observable<T> {
        return this.collection.doc<T>(componentId).valueChanges();
    }

    public delete(componentId: string): Observable<void> {
        return this.update(componentId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> => from(this.collection.doc<T>(componentId).delete())
            )
        );
    }
}
