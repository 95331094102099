import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';
import { TriggerMetricsSegments, TriggererSegment } from 'src/app/core/interfaces/statistics-triggers-segment.interface';
import { MetricService } from 'src/app/core/services/metric.service';
import { takeUntil, take } from 'rxjs/operators';
import { TableColumn } from 'src/app/shared/components/generic-table/column.interface';
import { Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-statistics-triggers-segments',
  templateUrl: './statistics-triggers-segments.component.html',
  styleUrls: ['./statistics-triggers-segments.component.scss']
})
export class StatisticsSegmentsComponent implements OnChanges, OnDestroy {
  @Input() dates: { begin: Date, end: Date };
  @Input() triggerId: string;
  @Input() currentSiteId: string;

  @ViewChild(MatSort) public set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) public set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    this.setDataSourceAttributes();
  }
  private paginator: MatPaginator;
  private sort: MatSort;

  public segments: {id: string, type: string}[] = [];
  public triggerSegments: any[];
  public segmentsLoading: boolean = true;
  public triggerSegmentsTable: MatTableDataSource<any>;
  public metricsSegments: TriggererSegment[];
  public structureSegmentsColumns: TableColumn<TriggererSegment>[] = [
  ];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly metricService: MetricService,
    private readonly router: Router,
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe
  ) { }

  ngOnChanges(): void {
    this.segmentsLoading = true;
    this.unsubscribe.next();
    this.loadTriggerSegments();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initColumns = () => {
    //TODO: This could be more generic
    this.structureSegmentsColumns = [
      {
        Title: 'ID',
        columnDef: 'segmentId',
        type: 'url',
        Value: (segment: TriggererSegment) => segment.segmentId,
        ValueUrl: (segment: TriggererSegment) => {
          const index = this.segments.findIndex(seg => seg.id === segment.segmentId.toString());
          if (index !== -1) {
            const typeUrl = this.segments[index].type === 'historical' ? '/segments/list-historical/' : 'segments/list-real-time/';
            return typeUrl + segment.segmentId.toString();
          }
          return '';
        },
        isSortable: true
      }
    ];
  }

  public getArticlesCTRFormated(interactions: number, impressions: number): string {
    let ctr = interactions && impressions ? (interactions / impressions)*100 : 0;
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ' (' + ctrString + '%)';
  }

  public sumMetricsArticles(triggerMetricsArticles: TriggerMetricsSegments): any[] {

    //TODO: we need to refactor this, I don't think we actually need segmentsListA and segmentsListB
    const segmentsListA = [];
    const segmentsListB = [];
    const result = [];
    const uniqueKeys = new Set(triggerMetricsArticles.triggererSegments.map(segment => Object.keys(segment).filter(key => key !== "segmentId")).flat());


    triggerMetricsArticles.triggererSegments.forEach(segment => {
      const currentSegment = segmentsListA.find(auxSegment => auxSegment.segmentId === segment.segmentId);
      if (currentSegment) segmentsListB.push(segment);
      else segmentsListA.push(segment);
    })

    segmentsListA.forEach(segment => {
      const records = triggerMetricsArticles.triggererSegments.filter(s => s.segmentId === segment.segmentId);
      const response = { segmentId: segment.segmentId };

      uniqueKeys.forEach( key => {
        response[key] = records.map((element) => element[key] || 0).reduce((prv, acc) => prv + acc);
      })
      result.push(response);
    })

    return result;
  }

  private loadSegmentsName() {
    this.metricsSegments.forEach(segment => {
      this.segments.push({ id: segment.segmentId.toString(), type: 'realtime' });
    });
  }

  private loadTriggerSegments() {
    this.metricService.getAllTriggerActivationsGroupedBySegmentId(this.triggerId, this.currentSiteId, this.dates.begin, this.dates.end).pipe(
      takeUntil(this.unsubscribe)).subscribe((triggerMetricsSegments: TriggerMetricsSegments): void => {

        this.initColumns();
        const keys = this.getKeysFromFirstElement(triggerMetricsSegments.triggererSegments);
        this.populateColumns(keys);

        this.metricsSegments = this.sumMetricsArticles(triggerMetricsSegments);
        this.loadSegmentsName();
        this.setTableData();
    });

  }

  populateColumns = (keys: string[]) => {
    //TODO: The generation of columns could be a separate function, even it can be translated to the model.
    const columns: TableColumn<TriggererSegment>[] = keys.filter(key => key !== "segmentId").map(key => ({
      Title: key.toLocaleUpperCase(),
      columnDef: key,
      type: 'text',
      Value: (segment: TriggererSegment) => segment[key].toString() + this.getArticlesCTRFormated(segment[key], segment.impressions),
      isSortable: true,
    }));

    this.structureSegmentsColumns.push(...columns);

    this.structureSegmentsColumns.push({
      Title: '',
      columnDef: 'statistics',
      type: 'statistics',
      Value: (segment: TriggererSegment) => '',
    });
  }

  getKeysFromFirstElement = (array) => {
    if (!array || !array.length) return [];

    return Object.keys(array[0]);
  }

  private setDataSourceAttributes(): void {
    if (this.triggerSegmentsTable) {
        this.triggerSegmentsTable.paginator = this.paginator;
        this.triggerSegmentsTable.sort = this.sort;
    }
  }

  private setTableData(): void {
    this.triggerSegmentsTable = new MatTableDataSource(this.metricsSegments);

    this.triggerSegmentsTable.sortingDataAccessor = (item: any, property: string): string | number => {
      switch (property) {
          case 'segmentId':
            return item.segmentId.toString().toLowerCase();
          default:
            return item[property];
          }
      };
      this.segmentsLoading = false;
  }

  public async onButtonStatisticsClicked(segment: TriggererSegment): Promise<void> {
    const startDate = this.datePipe.transform(this.dates.begin, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.dates.end, 'yyyy-MM-dd');
    this.router.navigate(['../../statistics/segments'], { queryParams: { id: segment.segmentId, startDate, endDate } });
  }
}
