import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap, takeUntil, first } from 'rxjs/operators';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import TestAbInterface from 'src/app/core/interfaces/trigger/test-ab.interface';
import { PushSubscriptionInterface } from 'src/app/core/interfaces/push-subscription.interface';
import { NewslettersSubscriptionInterface } from 'src/app/core/interfaces/newsletters-subscription.interface';
import TriggerMetaInterface from 'src/app/core/interfaces/trigger/trigger-meta.interface';
import TestAbModel from 'src/app/core/models/test-ab.model';
import { AllSegmentsService } from 'src/app/core/services/all-segments.service';
import { AllTriggersService } from 'src/app/core/services/all-triggers.service';
import { PushSubscriptionService } from 'src/app/core/services/push-subscription.service';
import { NewslettersSubscriptionService } from 'src/app/core/services/newsletters-subscription.service';
import { MediaService } from 'src/app/core/services/media.service';
import { TriggerTemplateInterface } from 'src/app/core/interfaces/trigger-template.interface';
import { TriggerFunctionInterface } from 'src/app/core/interfaces/trigger-function.interface';
import { RoleService } from 'src/app/core/services/role.service';
import { TestAbService } from 'src/app/core/services/test-ab.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { AppPermissions } from 'src/app/shared/utils/app-permissions';
import { ConstantsTriggers } from 'src/app/shared/utils/constants-triggers';
import { TriggerUtils } from 'src/app/shared/utils/triggers-utils';
import { Utils } from 'src/app/shared/utils/utils';
import { ProgrammableUtils } from 'src/app/shared/utils/programmable-utils';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { CloudFunctionsService } from 'src/app/core/services/cloud-functions.service';
import { TestABUtils } from 'src/app/shared/utils/test-ab-utils';
import { CacheService } from 'src/app/core/services/cache.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { SegmentRealTimeInterface } from 'src/app/core/interfaces/query-builder/segment-real-time.interface';

@Component({
    selector: 'app-triggers-test-ab-form',
    templateUrl: './triggers-test-ab-form.component.html',
    styleUrls: ['./triggers-test-ab-form.component.scss'],
})
export class TriggersTestAbFormComponent implements OnInit {
    public testAb: TestAbInterface;
    triggerTypes = [...ConstantsTriggers.globalTriggerTypesVector];
    initialSegments: { id: string; name: string; type: number }[];
    finalSegments: { id: string; name: string; type: number }[];
    templatesFiltered: TriggerTemplateInterface[];
    functions: TriggerFunctionInterface[];

    triggerButtons = [];

    formGroupStep1: UntypedFormGroup;
    formGroupStep4: UntypedFormGroup;
    showCloseButton: boolean;
    ampUnchecked = true;
    disableCompatible = false;
    canCreateCompatibleTriggers: boolean;
    canCreateAmpTriggers: boolean;
    isEditMode: boolean;

    public chipsTitles = CONSTANTS.SEGMENTS_TYPES_NAMES;

    originalTriggerListInTestAB = [];

    editorConfig = {
        id: 'editor',
        projectId: 4162,
        locale: 'en',
        tools: {
            form: {
                enabled: false,
            },
            'custom#datasources': {
                enabled: false,
            },
        },
    };
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    errorMessage: string;

    private unsubscribe: Subject<void> = new Subject();
    private currentSiteId: string;
    private currentSiteType: string;

    get programDates(): UntypedFormArray {
        return this.formGroupStep1.get('programDates') as UntypedFormArray;
    }

    get minProgramDate(): Date {
        return ProgrammableUtils.minProgramDate();
    }

    public formGroupStep2: UntypedFormGroup;

    public allTriggers: any[];
    public currentTriggers: any[];
    public previsualizationData: any[];

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly testAbService: TestAbService,
        private readonly mediaService: MediaService,
        private readonly allSegmentsService: AllSegmentsService,
        private readonly roleService: RoleService,
        private readonly allTriggersService: AllTriggersService,
        private readonly triggerService: TriggerService,
        private readonly pushSubscriptionService: PushSubscriptionService,
        private readonly newslettersSubscriptionService: NewslettersSubscriptionService,
        private readonly ref: ChangeDetectorRef,
        private readonly cloudFunctionsService: CloudFunctionsService,
        private readonly cacheService: CacheService,
    ) {}

    ngOnInit(): void {
        const testAb$: Observable<TestAbInterface> = this.route.params.pipe(
            map((params: Params): string => params['id'] || 'create'),
            switchMap((id: string): Observable<TestAbInterface> => this.testAbService.getById(id))
        );

        const segments$: Observable<SegmentRealTimeInterface[]> = this.mediaService.getCurrentSite().pipe(
            tap((currentSiteId: string): void => {
                this.currentSiteId = currentSiteId;
            }),
            switchMap(
                (currentSiteId: string): Observable<SegmentRealTimeInterface[]> =>
                    this.allSegmentsService.getAllBySite(currentSiteId)
            )
        );

        const currentSiteType$ = this.mediaService.getCurrentSiteType();

        const triggers$: Observable<any> = this.mediaService.getCurrentSite().pipe(
            switchMap(currentSiteId => {
                return this.allTriggersService.getAllByMedia(currentSiteId);
            })
        );

        combineLatest([testAb$, segments$, triggers$, currentSiteType$])
            .pipe(
                takeUntil(this.unsubscribe),
            )
            .subscribe(([testAb, segments, triggers, currentSiteType]) => {
              this.currentSiteType = currentSiteType;
              if (testAb && testAb.siteId !== this.currentSiteId) this.mediaService.changeSite(testAb.siteId);
              this.isEditMode = !!testAb;
              this.testAb = new TestAbModel(testAb);
              if (this.isEditMode) this.originalTriggerListInTestAB = this.testAb.triggers.map(t => t.id);
              this.createForm();
              this.subscribeToForms();
              const { initialSegments, finalSegments } = TriggerUtils.refreshSegments(segments, this.testAb);
              this.initialSegments = initialSegments;
              this.finalSegments = finalSegments;
              this.allTriggers = triggers;
              this.currentTriggers = [...triggers];
            });
    }

    public finish(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    private createForm(): void {
      //TODO: utilizar this.formGroup.get('isCompatible').disable() cuando se quiere deshabilitar isCompatible
        this.formGroupStep1 = this.formBuilder.group({
          active: [this.testAb.active, Validators.required],
          name: [this.testAb.name, Validators.required],
          description: [this.testAb.description],
          frequency: [
            this.testAb.frequency,
            Validators.compose([Validators.required, Validators.min(0), Validators.max(31)]),
          ],
          templatesNumber: [
            this.testAb.templatesNumber,
            Validators.compose([Validators.required, Validators.min(2)]),
          ],
          type: [this.testAb.type, Validators.required],
          isCompatible: [
            {
              value: !!this.testAb.isCompatible,
              disabled: !this.roleService.getPermissions()[
                AppPermissions.ACTIVADORES.COMPATIBLE_TRIGGERS_CREATE.id
              ],
            },
          ],
          programDates: this.formBuilder.array([]),
          resolveTest: this.testAb.resolveTest,
          minCTR: this.testAb.minCTR || 10,
          addMoreConditions: this.testAb.addMoreConditions,
          minImpressionsCheck: this.testAb.minImpressionsCheck,
          minImpressions: this.testAb.minImpressions || 5000,
          minDateCheck: this.testAb.minDateCheck,
          resolveDateEnd: new Date(this.testAb.resolveDateEnd) || new Date(TestABUtils.getNowMilliseconds())
        });

        this.testAb.programDates.forEach(programDate => {
            this.addProgramDate(null, programDate.toDate());
        });

        this.formGroupStep1.get('type').valueChanges.subscribe(type => this.setCurrentTriggers(type));
    }

    private subscribeToForms() {
      this.formGroupStep1
            .get('resolveTest')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(resolvedTest => {
              if (!resolvedTest) {
                this.formGroupStep1.get('addMoreConditions').reset();
                this.formGroupStep1.get('minImpressionsCheck').reset();
                this.formGroupStep1.get('minDateCheck').reset();
                this.formGroupStep1.get('resolveDateEnd').reset();
              }
            });
      this.formGroupStep1
            .get('addMoreConditions')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(moreConditionAdded => {
              if (!moreConditionAdded) {
                this.formGroupStep1.get('minImpressionsCheck').reset();
                this.formGroupStep1.get('minDateCheck').reset();
                this.formGroupStep1.get('resolveDateEnd').reset();
              }
            });
      this.formGroupStep1
            .get('minDateCheck')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(minDateChecked => {
              if (!minDateChecked) this.formGroupStep1.get('resolveDateEnd').reset();
            });
    }

    selectedValueChangeStepper(event) {
        const index = event.selectedIndex;
        switch (index) {
            case 1:
                this.createOperationForm();
                break;
            case 3:
                this.previsualizationData.forEach(data => TriggerUtils.loadPrevisualization(data.html));
                break;
        }
    }

    public createOperationForm(): void {
        const templatesNumber = this.formGroupStep1.get('templatesNumber').value;
        this.formGroupStep2 = this.formBuilder.group({});

        for (let i = 0; i < templatesNumber; i++) {
            this.formGroupStep2.addControl(
                `trigger${i}`,
                new UntypedFormControl(
                    this.testAb.triggers.length && this.testAb.triggers[i] ? this.testAb.triggers[i].id : undefined,
                    Validators.required
                )
            );
        }

        this.formGroupStep2.valueChanges.subscribe(event => {
            this.setTriggers(event);
            this.ref.detectChanges();
        });

        if (this.testAb.triggers.length) {
            this.setTriggers(this.formGroupStep2.value);
            this.ref.detectChanges();
        }
        this.testAb.templatesNumber = templatesNumber;
    }

    public checkTriggerAvailability(triggerId: string): boolean {
        if(this.currentTriggers.find(trigger => trigger.id === triggerId).isIntoTestAB) return true;
        const index = this.testAb.triggers.findIndex(trigger => trigger.id === triggerId);
        return index !== -1;
    }

    public setTriggers(triggersFormValue: any): void {
        const triggers: TriggerMetaInterface[] = Object.values(triggersFormValue).map(t => ({
            id: t ? t.toString() : '',
            percentage: 100 / Object.values(triggersFormValue).length,
        }));
        this.testAb.triggers = triggers;
        if (!this.testAb.triggers.find(t => !t.id.length)) {
            this.previsualizationData = this.testAb.triggers.map(t => {
                const trigger = this.allTriggers.find(a => a.id === t.id);
                if (trigger) {
                    return {
                        id: trigger.id,
                        name: trigger.name,
                        html: trigger.html,
                    };
                }
            });
        }
    }

    public onSegmentsChange(segments: string[]): void {
        this.testAb.segments = segments;
    }

    private setCurrentTriggers(type): void {
      this.currentTriggers = this.allTriggers.filter(
        t =>
          t.type === ConstantsTriggers.globalTriggerTypes[type] ||
          ConstantsTriggers.globalTriggerTypes[type] === ConstantsTriggers.globalTriggerTypes[1]
      );
    }

    public async save(): Promise<void> {
        const originalState = this.testAb.active;
        const wasResolvingTest = this.testAb.resolveTest;
        this.prepareSubmitValues();

        this.isLoading$.next(true);
        this.unsubscribe.next();

        if (originalState || this.testAb.active) await this.updateTriggersInTestAB();

        if (!wasResolvingTest && this.testAb.resolveTest) this.testAb.resolveDateStart = TestABUtils.getNowMilliseconds();

        if (originalState != this.testAb.active) {
            const triggerListInTestAB = this.testAb.triggers.map(t => t.id);
            for (let triggerId of triggerListInTestAB) {
              const genericTrigger = this.allTriggers.find(trigger => trigger.id === triggerId);
              await this.updateTriggers(genericTrigger, [], triggerId, true, !this.testAb.active);
            }
        }

        if (this.isEditMode) {
            this.testAbService
                .update(this.testAb.id, this.testAb)
                .pipe(
                  takeUntil(this.unsubscribe),
                  switchMap(() => this.cacheService.updateSegmentsCacheForTriggers(this.currentSiteId))
                )
                .subscribe(
                    (): void => { this.showSuccess(); },
                    (err: unknown): void => {
                        this.showError(err);
                    }
                );
            return;
        }

       this.allTriggersService
        .getMaxPriorityByMedia(this.currentSiteId)
        .pipe(
          first(),
          switchMap( (maxPriority) =>{
            this.testAb.priority = maxPriority;
            return this.testAbService.add(this.testAb, true);
          }),
          switchMap(() => this.cacheService.updateSegmentsCacheForTriggers(this.currentSiteId)),
          takeUntil(this.unsubscribe)
        )
        .subscribe(
            (): void => { this.showSuccess() },
            (err: unknown): void => {
                this.showError(err);
            }
        );
    }

    private prepareSubmitValues(): void {
        this.testAb = {
            ...this.testAb,
            ...this.formGroupStep1.value,
            siteId: this.currentSiteId,
            siteType: this.currentSiteType,
        };

        this.testAb.resolveDateEnd = this.testAb.resolveDateEnd && this.testAb.minDateCheck ? new Date(this.testAb.resolveDateEnd).getTime() : TestABUtils.getNowMilliseconds();
        if (!this.isEditMode) this.testAb.id = this.triggerService.createId();

        // Programmable
        this.testAb.programDates = this.testAb.programDates.map(item => item['programDate']);
    }

    private async updateTriggersInTestAB(): Promise<void> {
        const finalTriggerListInTestAB = this.testAb.triggers.map(t => t.id);
        if (this.isEditMode) {
            for (let triggerId of this.originalTriggerListInTestAB) {
              const genericTrigger = this.allTriggers.find(trigger => trigger.id === triggerId);
              await this.updateTriggers(genericTrigger, finalTriggerListInTestAB, triggerId, false, false);
            }
        }
        for (let triggerId of finalTriggerListInTestAB) {
            const genericTrigger = this.allTriggers.find(trigger => trigger.id === triggerId);
            if (!this.originalTriggerListInTestAB.includes(triggerId))
              await this.updateTriggers(genericTrigger, finalTriggerListInTestAB, triggerId, true, false);
        }
    }

    private async updateTriggers(genericTrigger, finalTriggerListInTestAB: string[], triggerId: string,
                                 updateTestABList: boolean, isDesactived: boolean): Promise<void> {
      if (genericTrigger) {
        let element: TriggerInterface | PushSubscriptionInterface | NewslettersSubscriptionInterface;

        switch (genericTrigger.type) {
          case ConstantsTriggers.globalTriggerTypes[2]:
            element = await this.triggerService
              .get(genericTrigger.id)
              .pipe(first())
              .toPromise();
            break;

          case ConstantsTriggers.globalTriggerTypes[3]:
            element = await this.pushSubscriptionService
              .get(genericTrigger.id)
              .pipe(first())
              .toPromise();
            break;

          case ConstantsTriggers.globalTriggerTypes[4]:
            element = await this.newslettersSubscriptionService
              .getById(genericTrigger.id)
              .pipe(first())
              .toPromise();
            break;
        }
        this.modifyTriggerFromTestABList(element, genericTrigger, finalTriggerListInTestAB, triggerId, updateTestABList, isDesactived);
      }
    }

    private modifyTriggerFromTestABList(element: any, genericTrigger, finalTriggerListInTestAB: string[],
                                        triggerId: string, updateTestABList: boolean, isDesactived: boolean): void {
      if ((finalTriggerListInTestAB.length && !finalTriggerListInTestAB.includes(triggerId)) || isDesactived) {
        this.deleteFromTestABList(element, genericTrigger.type);
        this.updateEndDatePeriod(genericTrigger);
      }
      else if (updateTestABList) {
        this.updateTestABList(element, genericTrigger.type);
        this.deleteSegments(element, genericTrigger.type);
        this.createTriggerPeriod(genericTrigger, finalTriggerListInTestAB);
      }
    }

    private updateEndDatePeriod(genericTrigger: any): void {
      this.testAb.triggersPeriod.filter(period => period.triggerId === genericTrigger.id).forEach(trigger => {
        if (!trigger.endDate && this.isEditMode) trigger.endDate = TestABUtils.getNowMilliseconds();
      });
    }

    private async deleteFromTestABList(trigger: any, triggerType: string) {
        const index = trigger.testABList ? trigger.testABList.indexOf(this.testAb.id) : -1;
        if (index != -1) trigger.testABList.splice(index, 1);
        trigger.isIntoTestAB = trigger.testABList.length ? true : false;

        if (triggerType === ConstantsTriggers.globalTriggerTypes[2])
            await this.triggerService.update(trigger.id, {
                isIntoTestAB: trigger.isIntoTestAB,
                testABList: trigger.testABList,
            }).toPromise();
        else if (triggerType === ConstantsTriggers.globalTriggerTypes[3])
          await this.pushSubscriptionService.update(trigger.id, {
                isIntoTestAB: trigger.isIntoTestAB,
                testABList: trigger.testABList,
            }).toPromise();
        else if (triggerType === ConstantsTriggers.globalTriggerTypes[4])
          await this.newslettersSubscriptionService.update(trigger.id, {
                isIntoTestAB: trigger.isIntoTestAB,
                testABList: trigger.testABList,
            }).toPromise();
    }

    private createTriggerPeriod(genericTrigger: any, finalTriggerListInTestAB: string[]): void {
      const trigger = this.testAb.triggersPeriod.find(period => period.triggerId === genericTrigger.id);
        if (trigger && finalTriggerListInTestAB.includes(genericTrigger.id) && !this.originalTriggerListInTestAB.includes(genericTrigger.id) && this.isEditMode) {
          trigger.endDate = TestABUtils.getNowMilliseconds();
        }
        else if (this.testAb.active) {
          this.testAb.triggersPeriod.push({
            triggerId: genericTrigger.id,
            startDate: TestABUtils.getNowMilliseconds()
          });
        }
    }

    private deleteSegments(element: any, triggerType: string): void {

      if(this.formGroupStep1.get('active').value){
        switch (triggerType) {
          case ConstantsTriggers.globalTriggerTypes[2]:
            this.triggerService.update(element.id, {
              segments: []
            });
            break;

          case ConstantsTriggers.globalTriggerTypes[3]:
            this.pushSubscriptionService.update(element.id, {
              segments: []
            });
            break;

          case ConstantsTriggers.globalTriggerTypes[4]:
            this.newslettersSubscriptionService.update(element.id, {
              segments: []
            });
            break;
        }
      }
    }

    private updateTestABList(trigger: any, triggerType: string): void {
        if (trigger.testABList && !trigger.testABList.includes(this.testAb.id)) trigger.testABList.push(this.testAb.id);
        else trigger.testABList = [this.testAb.id];

        if (triggerType === ConstantsTriggers.globalTriggerTypes[2])
            this.triggerService.update(trigger.id, {
                isIntoTestAB: true,
                testABList: trigger.testABList,
                programDates: [],
            });
        else if (triggerType === ConstantsTriggers.globalTriggerTypes[3])
            this.pushSubscriptionService.update(trigger.id, {
                isIntoTestAB: true,
                testABList: trigger.testABList,
                programDates: [],
            });
        else if (triggerType === ConstantsTriggers.globalTriggerTypes[4])
            this.newslettersSubscriptionService.update(trigger.id, {
                isIntoTestAB: true,
                testABList: trigger.testABList,
                programDates: [],
            });
    }

    showMessage(title: string, message: string, icon: SweetAlertIcon): void {
        Swal.fire(title, message, icon);
    }

    private async showError(err): Promise<void> {
        console.log(err);
        this.isLoading$.next(false);
        await Utils.notify(`Activador ${this.testAb.name}`, 'error', 'Ha ocurrido un error con el guardado');
        this.finish();
    }

    private async showSuccess(): Promise<void> {
        this.isLoading$.next(false);
        await Utils.notify(`Activador ${this.testAb.name}`, 'success', 'Guardado realizado con éxito');
        this.finish();
    }

    addProgramDate($event?, value?: Date): void {
        $event && $event.preventDefault();
        ProgrammableUtils.addProgramDate(this.programDates, value);
    }

    removeProgramDate($event, i: number): void {
        $event.preventDefault();
        ProgrammableUtils.removeProgramDate(this.programDates, i);
    }

}
