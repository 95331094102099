import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SegmentRealTimeInterface } from '../interfaces/query-builder/segment-real-time.interface';
import { RealTimeSegmentService } from './real-time-segment.service';

@Injectable()
export class AllSegmentsService {
    constructor(
        private realTimeSegmentService: RealTimeSegmentService,
    ) {}

    getAllBySite(siteId: string): Observable<SegmentRealTimeInterface[]> {
        return this.realTimeSegmentService
            .getAllBySite(siteId, true)
            .pipe(
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.filter((segment: SegmentRealTimeInterface): boolean => segment.status)
                ),
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.map(
                        (segment: SegmentRealTimeInterface): SegmentRealTimeInterface => {
                            segment.historical = false;
                            return segment;
                        }
                    )
                )
            );
    }

    getAllBySiteApp(siteId: string, typePlatform: string): Observable<SegmentRealTimeInterface[]> {

        return this.realTimeSegmentService
            .getAllAppBySite(siteId, typePlatform)
            .pipe(
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.filter((segment: SegmentRealTimeInterface): boolean => segment.status)
                ),
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.map(
                        (segment: SegmentRealTimeInterface): SegmentRealTimeInterface => {
                            segment.historical = false;
                            return segment;
                        }
                    )
                )
            );
    }
}
