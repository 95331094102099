import { environment } from 'src/environments/environment';
import { CONSTANTS } from './constants';

export class NOTIFICATIONCONSTANTS {
    public static readonly WEEKDAYS = [
        CONSTANTS.MONDAY,
        CONSTANTS.TUESDAY,
        CONSTANTS.WEDNESDAY,
        CONSTANTS.THURSDAY,
        CONSTANTS.FRIDAY,
        CONSTANTS.SATURDAY,
        CONSTANTS.SUNDAY,
    ];

    public static readonly MESSAGETYPES = [
        CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE
    ];

    public static readonly ENDPOINTTYPES = [
        CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE,
        CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE,
    ];

    public static readonly TOPICS_URL = `${environment.pushNotificationAPI.baseUrl}/pushNotification/topics`;
}
